import Box  from '@mui/material/Box';
import Image from 'next/image';


const HomeBanner = () => {

  return (
    <Box 
        className="position-relative" 
         sx={{
          width: '100%', 
          height: {
            xs: '50px',
            md:'150px'
          }
       }}
      >
        <Image 
          src={'/images/quick-delivery.jpg'} 
          alt={'section-banner'} 
          fill 
          loading='lazy'
          quality={100} 
          placeholder="blur" 
          blurDataURL={process.env.NEXT_PUBLIC_BLUR_DATA_URL}
          sizes="(max-width: 768px) 50vw, (max-width: 1200px) 33vw, 33vw" 
        />
    </Box>
  )
}

export default HomeBanner